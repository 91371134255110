import { useEffect } from 'react';

import zendesk from '#utils/zendesk';

const ZendeskChat = () => {
  useEffect(() => {
    zendesk.init();
  }, []);

  return null;
};

export default ZendeskChat;
